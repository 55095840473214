body {
  margin: 0px;
  /* background-color: #e5e7eb; */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiGrid-container {
  width: 100% !important;
  margin-left: 0px !important;
}
.MuiGrid-container > .MuiGrid-item {
  padding-left: 0px !important;
}
.css-1lt5qva-MuiCardContent-root,
.css-15q2cw4 {
  padding: 0 !important;
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 3px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.support-wrap {
  height: 50px;
  padding: 0 !important;
  margin: 0 !important;
  background: #4c9eea;
  .title {
    width: 100%;
    text-align: center;
    line-height: 60px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: #fff;
  }
}

.introduction {
  width: 100%;
  background-color: #ede7f6; /* Màu nền nhạt */
  padding: 10px; /* Khoảng cách nội dung */
  margin: 15px 0 5px 0; /* Khoảng cách trên dưới */
  border-radius: 8px; /* Bo góc */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Đổ bóng nhẹ */
}

.introduction .title {
  color: #1565c0; /* Màu liên kết */
  text-align: center; /* Canh giữa tiêu đề */
  font-size: 18px; /* Cỡ chữ tiêu đề */
  font-weight: bold; /* Chữ in đậm */
  margin-bottom: 10px; /* Khoảng cách dưới tiêu đề */
  list-style: none; /* Loại bỏ dấu chấm */
  margin-left: 0;
}

.introduction p {
  margin: 2px 0; /* Khoảng cách trên dưới của các đoạn văn */
  display: list-item; /* Biến các thẻ p thành dạng list */
  list-style-type: disc; /* Dấu chấm đen tròn */
  margin-left: 20px; /* Thụt vào để dấu chấm rõ hơn */
  color: #333; /* Màu chữ */
  line-height: 1.5; /* Khoảng cách dòng */
}

.introduction a {
  color: #1565c0; /* Màu liên kết */
  text-decoration: none; /* Loại bỏ gạch chân */
}

.introduction a:hover {
  text-decoration: underline; /* Gạch chân khi hover */
}

.chat-input-container {
  position: sticky;
  bottom: 0; /* Cố định ở phía dưới */
  background-color: #fff; /* Nền trắng */
  padding: 0;
  z-index: 10; /* Đảm bảo luôn hiển thị trên các phần khác */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Đổ bóng phía trên */
}

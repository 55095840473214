/* Reset toàn bộ padding và margin */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Widget Container */
.chat-widget {
  position: fixed;
  top: 6px;
  left: 1px;
  z-index: 1000;
  width: 50px;
  height: 50px;
}

/* Icon Chat */
.chat-icon {
  /* background-color: #007bff; */
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  position: relative;
}

/* Badge tin nhắn chưa đọc */
.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
